<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle> Тест: {{ test.name || 'Нет названия' }} </VToolbarTitle>

            <VSpacer />

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  small
                  color="blue"
                  v-bind="attrs"
                  :to="{
                    name : Names.R_TALENT_TEST_TOPICS,
                    params : {
                      testId : testId
                    }
                  }"
                  v-on="on"
                >
                  <VIcon
                    small
                  >
                    fal fa-list
                  </VIcon>
                </VBtn>
              </template>
              <span>Список топиков</span>
            </VTooltip>
          </VToolbar>

          <VDivider />

          <VCardText>
            <VExpansionPanels
              accordion
              flat
            >
              <VExpansionPanel>
                <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
                <VExpansionPanelContent>
                  <code
                    class="elevation-0 d-block"
                    style="white-space: pre"
                  >{{ test }}</code>
                </VExpansionPanelContent>
              </VExpansionPanel>
            </VExpansionPanels>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'TestView',
  inject: ['Names'],
  data() {
    return {
      test: {},
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
  },
  async created() {
    try {
      this.test = await this.$di.api.Talent.testGet({ testId: this.testId });
    } catch (error) {
      this.$di.notify.errorHandler(error);
    }
  },
};
</script>
